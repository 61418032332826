// Importing third party components

import { FadeTransition, CollapseTransition } from "vue2-transitions"
const FlatPickr = () => import("vue-flatpickr-component")

// Importing common components
const AnimatedLoader = () => import("./AnimatedLoader/index.vue")
const Progress = () => import("./Progress.vue")
const BackToTop = () => import("./BackToTop.vue")
const Badge = () => import("./Badge.vue")
const BaseAlert = () => import("./BaseAlert.vue")
const BaseButton = () => import("./BaseButton.vue")
const BaseContainer = () => import("./BaseContainer.vue")
const BaseCheckbox = () => import("./BaseCheckbox.vue")
const BaseDropdown = () => import("./BaseDropdown.vue")
const ButtonDropdown = () => import("./ButtonDropdown.vue")
const BaseInput = () => import("./BaseInput.vue")
const BaseTextarea = () => import("./BaseTextarea.vue")
const BaseNav = () => import("./BaseNav.vue")
const BaseProgress = () => import("./BaseProgress.vue")
const BaseRadio = () => import("./BaseRadio.vue")
const BaseSlider = () => import("./BaseSlider.vue")
const BaseSwitch = () => import("./BaseSwitch.vue")
const BaseSelect = () => import("./BaseSelect.vue")
const Card = () => import("./Card.vue")
const DateBetween = () => import("./DateBetween.vue")
const Icon = () => import("./Icon.vue")
const Placeholder = () => import("./Placeholder.vue")
const VueAt = () => import("./VueAt.vue")

const MultiselectWrapper = () => import("./MultiselectWrapper.vue")

const SwitchWrapper = () => import("./SwitchWrapper.vue")
const FeedWrapper = () => import("./FeedWrapper.vue")
const TableWrapper = () => import("./TableWrapper.vue")
const TransitionPage = () => import("./TransitionPage.vue")
const CloseButton = () => import("./CloseButton.vue")

const ViewSingle = () => import("./ViewSingle.vue")
const ViewDate = () => import("./ViewDate.vue")
const ViewParagraph = () => import("./ViewParagraph.vue")
const ViewUploads = () => import("./ViewUploads.vue")
const ViewHtml = () => import("./ViewHtml.vue")
const ViewList = () => import("./ViewList.vue")
const ViewCurrency = () => import("./ViewCurrency.vue")
const ViewBadge = () => import("./ViewBadge.vue")

export default {
    install(Vue) {
        // Registering third party components
        Vue.component('date-picker', FlatPickr)
        Vue.component('fade-transition', FadeTransition)
        Vue.component('collapse-transition', CollapseTransition)

        // Registering custom components
        Vue.component('AnimatedLoader', AnimatedLoader)
        Vue.component('Progress', Progress)
        Vue.component('BackToTop', BackToTop)
        Vue.component('Badge', Badge)
        Vue.component('BaseAlert', BaseAlert)
        Vue.component('BaseButton', BaseButton)
        Vue.component('BaseContainer', BaseContainer)
        Vue.component('BaseDropdown', BaseDropdown)
        Vue.component('ButtonDropdown', ButtonDropdown)
        Vue.component('BaseInput', BaseInput)
        Vue.component('BaseTextarea', BaseTextarea)
        Vue.component('BaseNav', BaseNav)
        Vue.component('BaseCheckbox', BaseCheckbox)
        Vue.component('BaseProgress', BaseProgress)
        Vue.component('BaseRadio', BaseRadio)
        Vue.component('BaseSlider', BaseSlider)
        Vue.component('BaseSwitch', BaseSwitch)
        Vue.component('BaseSelect', BaseSelect)
        Vue.component('Card', Card)
        Vue.component('DateBetween', DateBetween)
        Vue.component('Icon', Icon)
        Vue.component('SwitchWrapper', SwitchWrapper)
        Vue.component('MultiselectWrapper', MultiselectWrapper)
        Vue.component('Placeholder', Placeholder)
        Vue.component('VueAt', VueAt)

        Vue.component('TableWrapper', TableWrapper)
        Vue.component('TransitionPage', TransitionPage)
        Vue.component('FeedWrapper', FeedWrapper)
        Vue.component('CloseButton', CloseButton)

        Vue.component('ViewSingle', ViewSingle)
        Vue.component('ViewDate', ViewDate)
        Vue.component('ViewParagraph', ViewParagraph)
        Vue.component('ViewUploads', ViewUploads)
        Vue.component('ViewHtml', ViewHtml)
        Vue.component('ViewList', ViewList)
        Vue.component('ViewCurrency', ViewCurrency)
        Vue.component('ViewBadge', ViewBadge)
    }
}
