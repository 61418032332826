const GoogleAnalytics = {
    install(Vue, options = {}) {
        const vm = new Vue({})

        Vue.prototype.$gaEvent = (category, action, label, value) => {
            if(! (window.kmenv && window.kmenv.gaid) || !category || !action) {
                return
            }

            let opts = {
                event_category: category,
            }

            if(label) {
                opts.event_label = label
            }

            if(value) {
                opts.value = value
            }

            this.$gtag.event(action, opts)
        }
    }
}

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(GoogleAnalytics)
}

export default GoogleAnalytics
