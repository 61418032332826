import { getOptionRoutes } from './config/module-config'
import store from '@js/store'

export default [{
        path: 'instant-meetings',
        name: 'appInstantMeeting',
        redirect: { name: 'appInstantMeetingList' },
        meta: {
            title: 'meeting.instant_meetings',
            icon: 'fas fa-business-time',
            permissions: ['list-meeting', 'create-meeting', 'edit-meeting'],
        },
        component: {
            template: '<router-view></router-view>'
        },
        children: [{
                path: 'start',
                name: 'appInstantMeetingStart',
                meta: {
                    title: 'meeting.start_a_meeting',
                    permissions: ['create-meeting']
                },
                component: () => import('@views/app/meeting/start.vue'),
            },
            {
                path: 'join',
                name: 'appInstantMeetingJoin',
                meta: {
                    title: 'meeting.join_a_meeting',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/join.vue'),
            },
            {
                path: 'history',
                name: 'appInstantMeetingList',
                meta: {
                    trans: 'global.list',
                    title: 'meeting.meeting_history',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/history.vue'),
            },
        ]
    },
    {
        path: 'meetings',
        name: 'appMeeting',
        redirect: { name: 'appMeetingList' },
        meta: {
            title: 'meeting.meetings',
            icon: 'far fa-calendar-alt',
            hideChildren: true,
            permissions: ['list-meeting', 'create-meeting', 'edit-meeting'],
        },
        component: {
            template: '<router-view></router-view>'
        },
        children: [{
                path: '',
                name: 'appMeetingList',
                meta: {
                    trans: 'global.list',
                    title: 'meeting.meetings',
                    permissions: ['list-meeting']
                },
                component: () => {
                    const configs = store.getters['config/configs']

                    if(configs && configs.meeting && configs.meeting.cardLayout) {
                        return import('@views/app/meeting/feed.vue')
                    }

                    return import('@views/app/meeting/index.vue')
                }
            },
            {
                path: 'config',
                name: 'appMeetingConfig',
                redirect: { name: 'appMeetingConfigGeneral' },
                meta: {
                    title: 'config.config',
                    permissions: ['access-meeting-config'],
                    isConfigRoute: true,
                },
                component: () => import('@views/app/meeting/config/index.vue'),
                children: [{
                        path: '',
                        name: 'appMeetingConfigGeneral',
                        meta: {
                            title: 'config.general',
                            permissions: ['access-meeting-config'],
                        },
                        component: () => import('@views/app/meeting/config/general.vue'),
                    }, {
                        path: 'pam',
                        name: 'appMeetingConfigPam',
                        meta: {
                            title: 'meeting.publicly_accessible_meetings',
                            permissions: ['access-meeting-config'],
                        },
                        component: () => import('@views/app/meeting/config/pam.vue'),
                    }, {
                        path: 'snapshot',
                        name: 'appMeetingConfigSnapshot',
                        meta: {
                            title: 'meeting.config.snapshot',
                            permissions: ['access-meeting-config'],
                        },
                        component: () => import('@views/app/meeting/config/snapshot.vue'),
                    }, {
                        path: 'recording',
                        name: 'appMeetingConfigRecording',
                        meta: {
                            title: 'meeting.config.recording',
                            permissions: ['access-meeting-config'],
                        },
                        component: () => import('@views/app/meeting/config/recording.vue'),
                    }, {
                        path: 'auto-end',
                        name: 'appMeetingConfigAutoEnd',
                        meta: {
                            title: 'meeting.config.auto_end',
                            permissions: ['access-meeting-config'],
                        },
                        component: () => import('@views/app/meeting/config/auto-end.vue'),
                    },
                    getOptionRoutes({
                        path: 'categories',
                        entityTitle: 'meeting.meeting_category.category',
                        entitiesTitle: 'meeting.meeting_category.categories',
                        entityDescription: 'meeting.meeting_category.module_description',
                        routeNamePrefix: 'appMeetingConfigCategory',
                        permissionRequired: 'access-meeting-config',
                        optionType: 'meeting_category',
                        hasSlug: false,
                    }),
                ]
            },
            {
                path: 'add',
                name: 'appMeetingAdd',
                meta: {
                    trans: 'global.add',
                    title: 'meeting.meeting',
                    permissions: ['create-meeting']
                },
                component: () => import('@views/app/meeting/add.vue'),
            },
            {
                path: ':uuid/edit',
                name: 'appMeetingEdit',
                meta: {
                    trans: 'global.edit',
                    title: 'meeting.meeting',
                    permissions: ['edit-meeting']
                },
                component: () => import('@views/app/meeting/edit.vue'),
            },
            {
                path: ':uuid/duplicate',
                name: 'appMeetingDuplicate',
                meta: {
                    trans: 'global.add',
                    title: 'meeting.meeting',
                },
                component: () => import('@views/app/meeting/edit.vue'),
            },
            {
                path: ':subUuid/invitees/add',
                name: 'appMeetingInviteeAdd',
                meta: {
                    trans: 'global.add',
                    title: 'meeting.invitee.invitees',
                },
                component: () => import('@views/app/meeting/invite.vue'),
            },
            {
                path: ':subUuid/config',
                name: 'appMeetingSingleConfig',
                meta: {
                    trans: 'global.config',
                    title: 'meeting.meeting_level',
                },
                component: () => import('@views/app/meeting/config.vue'),
            },
            {
                path: ':uuid/snapshots',
                name: 'appMeetingSnapshots',
                meta: {
                    trans: 'global.view',
                    title: 'meeting.snapshots',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/snapshots.vue'),
            },
            {
                path: ':uuid/recordings',
                name: 'appMeetingRecordings',
                meta: {
                    trans: 'global.view',
                    title: 'meeting.recordings',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/recordings.vue'),
            },
            {
                path: ':uuid/payment/receipt',
                name: 'appMeetingPaymentReceipt',
                meta: {
                    title: 'meeting.payment.receipt',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/payment/receipt.vue'),
            },
            {
                path: ':uuid/payment',
                name: 'appMeetingPayment',
                redirect: { name: 'appMeetingPaymentForm' },
                meta: {
                    title: 'meeting.paid_meeting',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/payment/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'appMeetingPaymentForm',
                        meta: {
                            trans: 'global.view',
                            title: 'meeting.paid_meeting',
                        },
                        components: {
                            form:  () => import('@views/app/meeting/payment/form.vue'),
                        },
                    },
                ]
            },
            {
                path: ':uuid',
                name: 'appMeetingView',
                meta: {
                    trans: 'global.view',
                    title: 'meeting.meeting',
                    permissions: ['list-meeting']
                },
                component: () => import('@views/app/meeting/view.vue'),
            },
        ]
    },
]
