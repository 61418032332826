export default {
    bind: function(el, binding, vnode) {
        el.scrollToThis = function(event) {
        }

        if(binding.value) {
        }
    },
    unbind: function(el) {
    }
}
