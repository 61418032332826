const env = import.meta.env

const envVars = {
    appName: env.VITE_APP_NAME,
    appEnv: env.VITE_APP_ENV,
    appVersion: env.VITE_APP_VERSION ?? '1.0',
    storageKey: env.VITE_APP_NAME + '_' + env.VITE_APP_ENV,
    storageVersionKey: env.VITE_APP_NAME + '_' + env.VITE_APP_ENV + '_version',
}

export default envVars
